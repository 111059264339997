class GeneralHelper {
	capitalize (text) {
		return `${text.charAt(0).toUpperCase()}${text.slice(1)}`
	}
	setCurrencyFormat (amount) {
		return `₦${new Intl.NumberFormat('en-US',{ minimumFractionDigits: 2}).format(parseFloat(amount))}`
	}
	decodeHtmlEntites(text){
		const textarea = document.createElement('textarea')
		textarea.innerHTML = text
		return textarea.value
	}
	encodeHtmlEntites(text){
		return String(text).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;')
	}
}
const generalHelper = new GeneralHelper()
export { generalHelper }