import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
//import store from './store'
import CKEditor from '@ckeditor/ckeditor5-vue'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import './themes/global.css'

import { imageHelper } from '@/helper/ImageHelper'
import { generalHelper } from '@/helper/GeneralHelper'


import { library } from '@fortawesome/fontawesome-svg-core'
import { faKey, faUser, faStar as fasStar, faStarHalfAlt, faCheck, faPlug, faWrench, faTachometerAlt, faBook, faChalkboard, faCalendar, faBookOpen, faList, faInfoCircle, faVideo, faPlus, faSignOutAlt, faSyncAlt, faDatabase  } from '@fortawesome/free-solid-svg-icons'
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


library.add(faKey, faUser, fasStar, faPlus, faStarHalfAlt, farStar, faCheck, faPlug, faWrench, faTachometerAlt, faBook, faChalkboard, faCalendar, faBookOpen, faList, faInfoCircle, faVideo, faSignOutAlt, faSyncAlt, faDatabase)

const options = {
  confirmButtonColor: '#09568d',
  cancelButtonColor: '#a6001a',
};

const app = createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(VueSweetalert2, options)
    //.use(store)
    .use(router)
    .use(CKEditor)

app.config.globalProperties.$imageHelper = imageHelper
app.config.globalProperties.$generalHelper = generalHelper

router.isReady().then(() => {
    app.mount('#app')
})