<template>
  <router-view/>
</template>

<script>
import axios from 'axios'
import { storageService } from '@/services/storage.service'

export default {
	name: 'App',
	created () {
		const thisRef = this
		axios.interceptors.response.use(function (response) {
			return response
		}, function (error) {
			if (error.response.status === 401) {
				storageService.removeLogin()
				thisRef.$router.push({
					path: '/'
				}, () => {})
			}
			return Promise.reject(error)
		})
	}
};
</script>

<style>
</style>
